import * as Types from '../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RichMessageEditorQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RichMessageEditorQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, name: string, email: string }> };


export const RichMessageEditorDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RichMessageEditor"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MentionPluginUser_User"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MentionPluginUser_User"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]} as unknown as DocumentNode;

/**
 * __useRichMessageEditorQuery__
 *
 * To run a query within a React component, call `useRichMessageEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRichMessageEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRichMessageEditorQuery({
 *   variables: {
 *   },
 * });
 */
export function useRichMessageEditorQuery(baseOptions?: Apollo.QueryHookOptions<RichMessageEditorQuery, RichMessageEditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RichMessageEditorQuery, RichMessageEditorQueryVariables>(RichMessageEditorDocument, options);
      }
export function useRichMessageEditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RichMessageEditorQuery, RichMessageEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RichMessageEditorQuery, RichMessageEditorQueryVariables>(RichMessageEditorDocument, options);
        }
export function useRichMessageEditorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RichMessageEditorQuery, RichMessageEditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RichMessageEditorQuery, RichMessageEditorQueryVariables>(RichMessageEditorDocument, options);
        }
export type RichMessageEditorQueryHookResult = ReturnType<typeof useRichMessageEditorQuery>;
export type RichMessageEditorLazyQueryHookResult = ReturnType<typeof useRichMessageEditorLazyQuery>;
export type RichMessageEditorSuspenseQueryHookResult = ReturnType<typeof useRichMessageEditorSuspenseQuery>;
export type RichMessageEditorQueryResult = Apollo.QueryResult<RichMessageEditorQuery, RichMessageEditorQueryVariables>;