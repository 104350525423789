import * as Types from '../../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisplayCommentBody_ReplyCommentFragment = { __typename?: 'BaseChatMessage', id: number, message: string, messageLexical?: string | null, isEdited: boolean, createdAt: any, updatedAt: any, user: { __typename?: 'DisplayUser', id: string, name: string } };

export type DisplayCommentBodyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DisplayCommentBodyQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, name: string }> };

export const DisplayCommentBody_ReplyCommentFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DisplayCommentBody_ReplyComment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"BaseChatMessage"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"message"}},{"kind":"Field","name":{"kind":"Name","value":"messageLexical"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}},{"kind":"Field","name":{"kind":"Name","value":"isEdited"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"}}]}}]} as unknown as DocumentNode;
export const DisplayCommentBodyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"DisplayCommentBody"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useDisplayCommentBodyQuery__
 *
 * To run a query within a React component, call `useDisplayCommentBodyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDisplayCommentBodyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDisplayCommentBodyQuery({
 *   variables: {
 *   },
 * });
 */
export function useDisplayCommentBodyQuery(baseOptions?: Apollo.QueryHookOptions<DisplayCommentBodyQuery, DisplayCommentBodyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DisplayCommentBodyQuery, DisplayCommentBodyQueryVariables>(DisplayCommentBodyDocument, options);
      }
export function useDisplayCommentBodyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DisplayCommentBodyQuery, DisplayCommentBodyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DisplayCommentBodyQuery, DisplayCommentBodyQueryVariables>(DisplayCommentBodyDocument, options);
        }
export function useDisplayCommentBodySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DisplayCommentBodyQuery, DisplayCommentBodyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DisplayCommentBodyQuery, DisplayCommentBodyQueryVariables>(DisplayCommentBodyDocument, options);
        }
export type DisplayCommentBodyQueryHookResult = ReturnType<typeof useDisplayCommentBodyQuery>;
export type DisplayCommentBodyLazyQueryHookResult = ReturnType<typeof useDisplayCommentBodyLazyQuery>;
export type DisplayCommentBodySuspenseQueryHookResult = ReturnType<typeof useDisplayCommentBodySuspenseQuery>;
export type DisplayCommentBodyQueryResult = Apollo.QueryResult<DisplayCommentBodyQuery, DisplayCommentBodyQueryVariables>;